body,html {
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.ecod-popup-ul li{
  margin-bottom: 2%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.button-city-card{
  font-style: "Segoe UI" !important;
  border-radius: 20px !important;
  color: transparent !important;
  text-transform:none !important;
  font-size: 1.1rem !important;
}

.button-primary{
  font-style: "Segoe UI" !important;
  border-radius: 20px !important;
  color: white !important;
  text-transform:none !important;
  font-size: 1.1rem !important;
}

.button-secondary{
  font-style: Segoe UI !important;
  background-color: white !important;
  border-radius: 20px !important;
  text-transform:none !important;
  font-size: 1.1rem !important;
}

.button-feedback{
  border-radius: 20px !important;
  border-color: #929292 !important;
  color: #929292;
}

.MuiButton-root .MuiButton-contained .MuiButton-containedPrimary .button-feedback{
  background: orange;
  color: white !important;
}

.line-through-icon{
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg viewBox='0 0 90 90' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3e%3cline x1='0' y1='100' x2='100' y2='0' stroke-width='2' stroke='%230B2437'/%3e%3c/svg%3e");
  background-size: 90% 90%;

}

.ecod-city-image{
  width: 100%;
}

.ecod-city-detail-text{
  text-align: left;
  font-size: 16pt;
}

.ecod-city-detail-text ul li{
  text-align: left;
  font-size: 16px;
  line-height: 24px;
}

.ecod-city-detail-wrapper ul{
  text-align: left;
  color: #3D3D3D;
  font-size: 16px;
  line-height: 24px;
}

.ecod-city-detail-wrapper li{
  text-align: left;
  color: #3D3D3D;
  font-size: 16px;
  line-height: 24px;
}
.ecod-city-detail-wrapper p{
  text-align: left;
  color: #3D3D3D;
  font-size: 16px;
  line-height: 24px;
}

.ecod-city-detail-wrapper h2{
  color:#182A3B;
  text-align: left;
  padding: 0;
  font-size: 1rem;
  line-height: 2.25rem;
  margin: 0 0 1%;
}

.ecod-city-detail-wrapper a{
  color: #61dafb;
}

.ecod-special-char{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

.ecod-subtext-scaling{
  font-size: 0.8em !important;
}

.ecod-city-detail-img img{
  object-fit: scale-down !important;
  width: 75% !important;
}

.ecod-city-detail-wrapper h1{
  color:#182A3B;
  text-align:left;
  padding: 0;
  font-size: 1.4rem;
}

.ecod-city-card{
  cursor: pointer;
}
* {
  box-sizing: border-box;
}
body {
  padding: 0;
  margin: 0;
  height:100%;
}

@media only screen and (max-width: 600px) {
  .ecod-starting-page{
    background-image: url("/public/assets/tutorial/start_page_mobile.png") !important;
    max-height: 100%;
  }

  .button-primary:hover{
    background: #009FE3 !important;
  }
}



@media (-webkit-device-pixel-ratio: 1.25) {
/*  .ecod-subtext-scaling{
    font-size: 0.7rem !important;
    line-height: 1rem !important;
  }*/
}

@media only screen and (min-width: 900px) {
  .ecod-city-image{
    width: 49%;
  }
}

@media only screen and (min-width: 1200px) {
  /*.ecod-city-image{
    width: 49%;
  }*/

  .ecod-city-detail-wrapper h1{
    text-align: left;
    font-size: 2.125rem;
    line-height: 2.75rem;
    font-weight: bold;
  }

  .ecod-city-detail-wrapper h2{
    text-align: left;
    font-size: 1.375rem;
    line-height: 2.25rem;
  }

  .ecod-city-detail-wrapper p{
    text-align: left;
    font-size: 18px;
    line-height: 36px;
    margin: 10px;
  }

  .ecod-city-detail-wrapper ul li{
    text-align: left;
    font-size: 18px;
    line-height: 36px;
  }

  .ecod-city-detail-wrapper ul{
    text-align: left;
    font-size: 18px;
    line-height: 36px;
  }

  .ecod-city-detail-wrapper li{
    text-align: left;
    font-size: 18px;
    line-height: 36px;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
